.nav-wrapper {
  width: 100%;
  position: relative;
  z-index: 900;
  background-color: #fff;
  .nav-wrapper-inner {
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width $max {}
    .animated-header-tractor {
      position: absolute;
      top: 8px;
      left: 155px;
      width: 82px;
      height: auto;
      animation: navTruckerMover 5s linear 0s;
      @media (max-width: #{map-get($breakpoints, lg)}) {
        top: 6px;
        left: 123px;
        width: 65px; }
      @media (max-width: #{map-get($breakpoints, md)}) {
        top: 6px;
        left: 100px;
        width: 50px; } }
    .logo-container {
      width: 312px;
      height: auto;
      position: relative;
      @media (max-width: #{map-get($breakpoints, lg)}) {
        width: 250px; }
      @media (max-width: #{map-get($breakpoints, md)}) {
        width: 200px; }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    .nav-container {
      display: flex;
      flex-direction: column;
      transition: $transition;
      @media (max-width: #{map-get($breakpoints, xxl)}) {
        position: absolute;
        height: 100vh;
        right: -40%;
        top: 130px;
        padding: 20px 0;
        background-color: #fff;
        width: 35%; }
      @media (max-width: #{map-get($breakpoints, lg)}) {
        width: 45%;
        right: -50%; }
      @media (max-width: #{map-get($breakpoints, md)}) {
        width: 70%;
        right: -75%; }
      .nav-meta-container {
        height: 75px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media (max-width: #{map-get($breakpoints, xxl)}) {
          position: relative;
          display: block;
          order: 2;
          margin-top: 25px;
          height: auto; }
        @media (max-width: #{map-get($breakpoints, lg)}) {
          margin-top: 10px; }
        .nav-meta-item {
          margin-left: 40px;
          display: flex;
          align-items: center;
          @media (max-width: #{map-get($breakpoints, xxl)}) {
            margin-bottom: 15px; }
          img {
            width: 23px;
            height: 23px;
            margin-right: 9px;
            filter: invert(48%) sepia(0%) saturate(940%) hue-rotate(146deg) brightness(88%) contrast(94%);
            @media (max-width: #{map-get($breakpoints, md)}) {
              width: 20px;
              height: 20px; } }
          a {
            color: $meta-nav-color;
            font-size: 1.4rem;
            text-decoration: none;
            font-family: SegoeUI;
            @media (max-width: #{map-get($breakpoints, sm)}) {
              font-size: 1.2rem; } }
          &:hover {
            a {
              color: $primary; }
            img {
              filter: $primary-filter; } } }

        .nav-meta-col {
          margin-left: 40px;
          width: 2px;
          height: 24px;
          background-color: $divider;
          @media (max-width: #{map-get($breakpoints, xxl)}) {
            display: none; } } }
      .nav-main-container {
        height: 75px;
        display: flex;
        align-items: center;
        @media (max-width: #{map-get($breakpoints, xxl)}) {
          position: relative;
          display: block;
          order: 1;
          height: auto; }
        #main-menu-id {
          li {
            list-style: none;
            display: inline;
            margin-left: 45px;
            border-bottom: 4px solid transparent;
            padding-bottom: 13px;
            transition: $transition;
            @media (max-width: #{map-get($breakpoints, xxl)}) {
              display: block;
              margin-bottom: 15px; }
            @media (max-width: #{map-get($breakpoints, lg)}) {
              display: block;
              margin-bottom: 7px; }
            a {
              text-decoration: none;
              font-size: 2.2rem;
              color: $body-color;
              @media (max-width: #{map-get($breakpoints, xxxl)}) {
                font-size: 1.75rem; }
              &:hover {
                text-decoration: none; } }

            &:hover {
              border-bottom: 4px solid $primary;
              transition: $transition; } }
          .active {
            border-bottom: 4px solid $primary; } }
        .nav-main-divider {
          width: 80%;
          height: 2px;
          background-color: $divider;
          display: block;
          margin: 25px auto;
          @media (min-width: #{map-get($breakpoints, xxl)}) {
            display: none; } } } } } }


@keyframes navTruckerMover {
    0% {
        transform: translateX(-500px); }
    100% {
        transform: translateX(0); } }

//Nav Opener
.nav-container_open {
  right: 0!important;
  transition: $transition; }

//BodyOverlay
.body_overlay {
  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.33); } }
