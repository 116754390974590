.block-imprint {
  .imprint-wrapper {
    max-width: $boxed;
    padding: 0;
    margin: auto;
    .imprint-wrapper-inner {
      padding: 0 50px; } }
  a {
    color: #1D1D1B;
    text-decoration: none;
    &:hover {
      text-decoration: underline; } } }
