
.btn {
  border-radius: 25px;
  height: 50px;
  padding: 10px 50px;
  font-size: 1.9rem;
  text-decoration: none;
  transition: $transition;
  width: 210px;
  align-items: center;

  &-primary {
    background-color: $primary;
    border: 2px solid $primary;
    color: $tertiary;
    &:hover {
      background-color: $tertiary;
      border: 2px solid $primary;
      color: $primary;
      transition: $transition; } }


  &-secondary {
    background-color: $secondary;
    border: 2px solid $secondary;
    color: $body-color;
    &:hover {
      background-color: $primary;
      border: 2px solid $secondary;
      color: $body-color;
      transition: $transition; } }

  &-tertiary {
    background-color: $tertiary;
    border: 2px solid $tertiary;
    color: $body-color;
    &:hover {
      background-color: $primary;
      border: 2px solid $tertiary;
      color: $tertiary;
      transition: $transition; } } }
