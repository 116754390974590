#nav-toggler-wrapper {
  @media only screen and (min-width: #{map-get($breakpoints, xxl)}) {
    display: none; } }

#nav-toggler-wrapper {
  position: absolute;
  z-index: 1000;
  right: 20px;
  top: 50px;
  right: 5%;
  .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; }

  &:hover {
 }    // opacity: 0.7

  &.is-active {
    &:hover {
 }      // opacity: 0.7

    .hamburger-inner {
      background-color: $secondary;

      &::before, &::after {
        background-color: $secondary; } } }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    width: 40px;
    height: 2px;
    background-color: $secondary;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    &::before, &::after {
      width: 40px;
      height: 2px;
      background-color: $secondary;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }

    &::before, &::after {
      content: "";
      display: block; }

    &::before {
      top: -10px; }

    &::after {
      bottom: -10px; } }

  .hamburger--spring {
    .hamburger-inner {
      top: 2px;
      transition: background-color 0s 0.13s linear;

      &::before {
        top: 10px;
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

      &::after {
        top: 20px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); } }

    &.is-active .hamburger-inner {
      transition-delay: 0.22s;
      background-color: transparent !important;

      &::before {
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(45deg); }

      &::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(-45deg); } } } }
