
$sliderTextColor: #fff;
$sliderIconBackgroundColor: #fff;

$sliderStripesBg-1: #b3c618;
$sliderStripesBg-2: #c51a1b;
$sliderStripesBg-3: #8aabc8;
$sliderStripesBg-4: #6d6d6d;

$sliderStripesFilter-1: invert(70%) sepia(36%) saturate(1023%) hue-rotate(22deg) brightness(104%) contrast(81%);
$sliderStripesFilter-2: invert(14%) sepia(94%) saturate(4511%) hue-rotate(352deg) brightness(81%) contrast(91%);
$sliderStripesFilter-3: invert(71%) sepia(28%) saturate(351%) hue-rotate(166deg) brightness(89%) contrast(88%);
$sliderStripesFilter-4: invert(40%) sepia(0%) saturate(524%) hue-rotate(170deg) brightness(104%) contrast(90%);

.slider-stripes-bg-1 {
    background-color: $sliderStripesBg-1; }

.slider-stripes-bg-2 {
    background-color: $sliderStripesBg-2; }

.slider-stripes-bg-3 {
    background-color: $sliderStripesBg-3; }

.slider-stripes-bg-4 {
    background-color: $sliderStripesBg-4; }


.slider-stripes-filter-1 {
    filter: $sliderStripesFilter-1; }

.slider-stripes-filter-2 {
    filter: $sliderStripesFilter-2; }

.slider-stripes-filter-3 {
    filter: $sliderStripesFilter-3; }

.slider-stripes-filter-4 {
    filter: $sliderStripesFilter-4; }




.block-slider-stripes {
    max-width: $max;
    margin: 0 auto;
    padding: 0;
    height: calc(100vh - 130px);
    .slider-stripes-wrapper {
        height: 100%;
        display: flex;
        .slider-stripes-background-image-container {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
            z-index: -1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0; }
        .slider-stripes-item {
            position: relative;
            transition: $transition;
            height: 100%;
            width: 310px;
            @media (max-width: #{map-get($breakpoints, sm)}) {
                margin-bottom: 10px; }
            .slider-stripes-content-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: 100%;
                width: 310px;
                &:hover {
                    cursor: pointer; }
                .slider-stripes-overlay-hover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 310px;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.33);
                    transition: $transition;
                    opacity: 0;
                    z-index: -1; }
                .slider-stripes-overlay {
                    opacity: 0.95;
                    width: 310px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1; }
                .slider-stripes-information-container {
                    padding: 0 50px 0 50px; }
                h3, p, a {
                    color: $sliderTextColor;
                    padding: 0;
                    margin: 0; }
                h3 {
                    @media (max-width: #{map-get($breakpoints, sm)}) {
                        font-size: 2.1rem; } }

                p {
                    font-size: 2.1rem;
                    font-family: 'RalewayRegular';
                    @media (max-width: #{map-get($breakpoints, sm)}) {
                        font-size: 1.6rem; } }

                a {
                    font-size: 2rem;
                    font-family: 'RalewaySemiBold';
                    text-decoration: none;
                    @media (max-width: #{map-get($breakpoints, sm)}) {
                        font-size: 1.6rem; }
                    & {
                        text-decoration: none; } }

                .slider-stripes-icon-container {
                    margin-top: 200px;
                    margin-bottom: 50px;
                    width: 135px;
                    height: 135px;
                    border-radius: 50%;
                    background-color: $sliderIconBackgroundColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 100%;
                        height: 100%;
                        display: inline-block;
                        padding: 30px; } }

                .slider-stripes-heading-container {
                    margin-bottom: 25px; }
                .slider-stripes-link-container {
                    position: absolute;
                    bottom: 10%;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    .slider-stripes-link-arrow {
                        width: 28px;
                        height: 14px;
                        margin-left: 10px;
                        transition: $transition;
                        img {
                            width: 100%;
                            height: 100%; } } }
                &:hover {
                    .slider-stripes-link-arrow {
                        margin-left: 20px;
                        transition: $transition; } } }

            &:hover {
                .slider-stripes-overlay-hover {
                    opacity: 1; } } } } }


.block-slider-stripes {
    .open {
        width: 60%!important;
        transition: $transition;
        .slider-stripes-content-container,
        .slider-stripes-overlay,
        .slider-stripes-overlay-hover {
            width: 310px; } } }


@media (max-width: #{map-get($breakpoints, xxl)}) {

    .block-slider-stripes {
        height: auto;
        .open {
            width: 50%!important; } }
    .slider-stripes-wrapper {
        flex-wrap: wrap; }
    .slider-stripes-item {
        width: 50%!important;
        .slider-stripes-content-container {
            width: 100%!important;
            &:hover {
                cursor: auto!important; }
            .slider-stripes-overlay-hover {
                height: 50%!important;
                width: 100%!important;
                bottom: 0;
                top: auto; }
            .slider-stripes-overlay {
                height: 50%!important;
                width: 100%!important;
                bottom: 0;
                top: auto!important; } }
        .slider-stripes-information-container {
            padding: 0 25px 0 25px!important;
            .slider-stripes-icon-container {
                margin-bottom: 25px!important; }
            .slider-stripes-description-container {
                height: 100px!important; }
            .slider-stripes-link-container {
                position: relative!important;
                bottom: 0!important;
                height: 50px!important;
                margin-bottom: 25px!important; } } } }


@media (max-width: #{map-get($breakpoints, lg)}) {
    .block-slider-stripes {
        height: auto;
        .open {
            width: 100%!important; } }
    .slider-stripes-wrapper {
        flex-wrap: wrap; }
    .slider-stripes-item {
        width: 100%!important;
        height: 100vh!important;
        .slider-stripes-content-container {
            .slider-stripes-overlay-hover {
                height: 100%!important; }
            .slider-stripes-overlay {
                height: 35%!important; } }
        .slider-stripes-information-container {
            padding: 0 20px 0 20px!important;
            position: absolute;
            bottom: 0;
            .slider-stripes-description-container {
                height: 70px!important; } } } }


@media (max-width: #{map-get($breakpoints, md)}) {
    .slider-stripes-item {
        width: 100%!important;
        height: 80vh!important; } }
