.block.block-text-media {
    margin: 0 auto;
    padding: 0;

    .img-right {
        flex-direction: row; }

    .img-left {
        flex-direction: row-reverse; }


    .text-media-wrapper {
        max-width: $boxed;
        margin: 0 auto;
        padding: 100px 0;


        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-wrap: wrap-reverse; }
        @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
            padding: 0; }


        .text-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 50px; }


        .media-wrapper {
            height: 500px;
            margin: 0;
            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                height: 400px; }
            img, iframe {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; } } }

    .text-media-wrapper-gray {
        background-color: #F5F5F5;
        .text-media-wrapper {
            padding: 0 0;
            max-width: $max; }

        .text-wrapper {
            padding: 100px 50px 100px 140px;
            margin: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding: 50px 50px; }
            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                padding: 50px 25px; } }

        .media-wrapper {
            margin: 0;
            padding: 0;
            height: auto;
            img, picture {
                width: 100%;
                height: 100%;
                object-fit: cover; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                height: 75vh; }
            @media only screen and (max-width: #{map-get($breakpoints, lg)}) {
                height: 50vh; } } } }
