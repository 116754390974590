$team-background-color-1: #0091A7;
$team-background-color-2: #357E81;
$team-background-color-3: #fff;
$team-color-1: #fff;
$team-color-2: $primary;
$team-color-3: #1D1D1B;
$team-filter-1: invert(100%) sepia(0%) saturate(3247%) hue-rotate(325deg) brightness(108%) contrast(111%);
$team-filter-2: invert(16%) sepia(87%) saturate(3413%) hue-rotate(347deg) brightness(89%) contrast(98%);


.block-team {
    padding: 40px 170px;
    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 35px 100px; }
    @media (max-width: #{map-get($breakpoints, lg)}) {
        padding: 35px 40px; }

    .team-headline-container {
        padding: 0 0 80px 0;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            padding: 0 0 40px 0; }

        h2 {
            text-align: center; } }

    .team-profil-container {
        margin-top: 6rem;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column; }

    .team-profil-container-row {
        @media (max-width: #{map-get($breakpoints, lg)}) {
            justify-content: center;
            flex-direction: row; } }


    .team-image {
        height: 500px;
        width: 100%;
        padding: 0 50px;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            padding: 0 10px;
            width: 50%;
            height: 400px; }
        @media (max-width: #{map-get($breakpoints, md)}) {
            width: 100%;
            margin: 0; }


        img, picture {
            object-fit: cover;
            width: 100%;
            height: 100%; } }

    .team-contact-info-container {
        text-align: left;
        display: flex;
        width: 100%;
        padding: 0 25px;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            padding: 0 10px;
            width: 49%;
            align-items: center;
            margin-left: 0%; }
        @media (max-width: #{map-get($breakpoints, md)}) {
            width: 100%;
            margin: 0; } }


    .team-contact-info-inner {
        padding: 25px;
        width: 100%;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            height: max-content;
            background-color: #fff; }
        .team-contact-heading-container {
            height: 70px;
            margin-bottom: 10px;
            h3 {
                color: $team-color-2; } }

        h5 {
            font-size: 16px; }

        a,p, h5 {
            font-size: 16px;
            color: $team-color-3;
            text-decoration: none;
            margin: 0;
            padding: 0; }

        a {
            &:hover {
                text-decoration: underline;
                color: $team-color-3;
                transition: $transition; } } }

    .team-content-wrapper {
        margin: 25px 0;
        .team-contact-info-item {
            display: flex;
            margin: 5px 0;
            align-items: center;
            .team-icon {
                width: 20px;
                height: 20px;
                margin-right: 15px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    filter: $team-filter-2; } } } } }
