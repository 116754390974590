// Base
// if not already defined in var.sass, active the following row
// $base-width: .8rem

// Breakpoints
// if not already defined in var.sass, active the following row
// $breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1440px)

// Column & Row Gap
$grid-gutter: 2 * $base-width;
$grid-gutter-medium: 4 * $base-width;
$grid-gutter-large: 6 * $base-width;

// Element Spacing
$grid-spacer: 2 * $base-width;
$grid-spacer-medium: 4 * $base-width;
$grid-spacer-large: 6 * $base-width;

// Optional
// $background: $light-grey

// Do not change below this line
.grid {
    width: 100%;
    overflow: hidden;

    .row {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 (-$grid-gutter);

        .col {
            padding: 0 ($grid-gutter);
            flex-basis: 100%;
            max-width: 100%;
            margin-bottom: 2 * $grid-gutter;
            position: relative;

            .col {
                margin-bottom: 0; }

            // Columns
            @for $i from 1 through 12 {
                &--#{$i} {
                    flex-basis: calc(100% / 12 * #{$i});
                    max-width: calc(100% / 12 * #{$i}); } }

            // Responsive
            @each $breakpoint, $width in $breakpoints {
                @for $i from 1 through 12 {
                    @media only screen and (min-width: #{$width}) {
                        &--#{$breakpoint}-#{$i} {
                            flex-basis: calc(100% / 12 * #{$i});
                            max-width: calc(100% / 12 * #{$i}); } } } }

            // Elements
            &__panel {
                height: 100%;
                padding: $grid-spacer;
                position: relative;
                @if variable-exists(background) {
                    background: $background; } }

            &__box {
                width: 100%; } }


        // Modifier
        &--gutter-medium {
            margin: 0 (-$grid-gutter-medium);

            .col {
                padding: 0 ($grid-gutter-medium);
                margin-bottom: 2 * $grid-gutter-medium; } }

        &--gutter-large {
            margin: 0 (-$grid-gutter-large);

            .col {
                padding: 0 ($grid-gutter-large);
                margin-bottom: 2 * $grid-gutter-large; } }

        &--spacer-medium {
            .col {
                &__panel {
                    padding: $grid-spacer-medium; } } }

        &--spacer-large {
            .col {
                &__panel {
                    padding: $grid-spacer-large; } } }

        &--reverse {
            flex-direction: row-reverse; } } }
