$button-bg: #444;
$button-color: #fff;
$button-bg-hover: #fff;
$button-color-hover: #444;
$gallery-sort-overlay: rgba(197, 26, 27, 0.6);




.block-gallery-sort {
    padding: 25px 200px;
    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 25px 100px; }
    @media (max-width: #{map-get($breakpoints, lg)}) {
        padding: 25px 50px; }
    .gallery-images-buttons-row {
        padding: 15px;
        margin: 0;
        .gallery-filter-button {
            background-color: $button-bg;
            color: $button-color;
            transition: $transition;
            padding: 15px 30px;
            margin: 10px 15px 10px 0px;
            border: solid 2px $button-bg;
            border-radius: 15px;
            p {
                margin: 0;
                padding: 0; }
            &:hover {
                background-color: $button-bg-hover;
                color: $button-color-hover;
                transition: $transition;
                border: solid 2px $button-bg;
                cursor: pointer; } }
        .gallery-filter-button-active {
            background-color: $button-bg-hover;
            color: $button-color-hover;
            transition: $transition;
            border: solid 2px $button-bg; } }
    .gallery-images-row {
        padding: 0;
        margin: 0; }
    .gallery-image-container {
        margin: 0;
        height: 350px;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            height: 300px; }
        img,picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
            .gallery-sort-overlay {
                position: absolute;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                opacity: 1;
                transition: $transition;
                z-index: 9;
                img {
                    width: 50px;
                    height: 50px;
                    transition: $transition; } }
            &:hover {
                .gallery-sort-overlay {
                    opacity: 1;
                    transition: $transition;
                    justify-content: center;
                    align-items: center;
                    background-color: $gallery-sort-overlay;
                    img {
                        width: 100px;
                        height: auto;
                        transition: $transition; } } } } } }




.fancybox-caption {
    font-size: 25px;
    @media (max-width: #{map-get($breakpoints, lg)}) {
        font-size: 20px; }
    @media (max-width: #{map-get($breakpoints, md)}) {
        font-size: 16px; } }
