/* Third-Party */

@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';

/* Globals */
@import './globals/vars';
@import './globals/fonts';
@import './globals/base';
@import './globals/bem-grid-beta';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/navigation';
@import './layout/hamburger';
@import './layout/footer';
@import './layout/buttons';
@import './layout/imprint';

/* Blocks */
@import '../../blocks/slider-stripes/style';
@import '../../blocks/headline/style';
@import '../../blocks/logo-wall/style';
@import '../../blocks/banner/style';
@import '../../blocks/slider-simple/style';
@import '../../blocks/text-media/style';
@import '../../blocks/gallery-sort/style';
@import '../../blocks/timeline/style';
@import '../../blocks/team/style';
@import '../../blocks/contact/style';
