// Base
$base-width: .8rem;

// Fonts
$font-default: 'RalewayRegular';

// Colors
$body-bg: white;
$body-color: #1D1D1B;

$primary: #C51A1B;
$primary-filter: invert(15%) sepia(61%) saturate(6735%) hue-rotate(353deg) brightness(80%) contrast(92%);

$secondary: #1D1D1B;
$tertiary: #fff;

$meta-nav-color: #6D6D6D;
$divider: rgba(109, 109, 109, 0.33);

// Widths
$max: 2560px;
$boxed: 1520px;
$small: 1100px;

// Transition
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "sm": 576px, "md": 768px, "lg": 992px, "xl": 1200px, "xxl": 1465px, "xxxl": 1680px);
