* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth; }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.6rem;
    color: $body-color;
    line-height: 160%;
    font-weight: 400;
    font-family: $font-default;
    overflow-x: hidden;

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
        font-size: 2rem; }

    &.customize-support {
 } }        // if logged in
img {
    display: block;
    max-width: 100%; }

main {
    position: relative; }

.block {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0 2 * $base-width;

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
        padding: 0 4 * $base-width; }

    .block:not(.block-container) {
        padding: 0; } }

.global-padding {
    padding: 0 140px;
    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 0 120px; }
    @media (max-width: #{map-get($breakpoints, lg)}) {
        padding: 0 60px; }
    @media (max-width: #{map-get($breakpoints, md)}) {
        padding: 0 30px; }
    @media (max-width: #{map-get($breakpoints, md)}) {
        padding: 0 15px; } }
