a {
    transition: $transition;

    &:focus {
        outline: 0; }

    &:active {
        text-decoration: underline; }

    &.button {
        display: inline-block;
        text-decoration: none;
        border-radius: 10px;
        padding: 1.5 * $base-width 3 * $base-width;
        transition: $transition; } }


main {
    a {
        color: $primary;
        text-decoration: none;
        &:hover {
            text-decoration: underline; } } }





strong,
.bold {
    font-weight: 700; }

p {
    padding-bottom: 3 * $base-width; }


button {
    &:focus {
        outline: none; } }

h1,
.h1 {
    font-family: $font-default;
    font-size: 3.2rem;
    line-height: 135%;
    display: block;
    font-weight: 700;
    color: $primary;
    margin-bottom: 4 * $base-width;
    text-transform: uppercase;

    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        font-size: 4.2rem; }


    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        font-size: 6rem; } }

h2,
.h2 {
    font-family: 'RalewaySemiBold';
    font-size: 2.4rem;
    line-height: 140%;
    display: block;
    font-weight: 600;
    color: $primary;
    margin-bottom: 2 * $base-width;
    text-transform: uppercase;

    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        font-size: 4rem; }

    @media only screen and (min-width: #{map-get($breakpoints, lg)}) {
        font-size: 4.8rem; } }

h3,
.h3 {
    font-family: 'RalewaySemiBold';
    color: $primary;
    font-size: 3rem;
    line-height: 125%;
    display: block;
    font-weight: 500;
    text-transform: uppercase;

    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        font-size: 2.4rem; } }

h4,
.h4 {
    font-family: 'RalewaySemiBold';
    color: $primary;
    font-size: 2rem;
    line-height: 125%;
    display: block;
    text-transform: uppercase;
    margin-bottom: 36px;

    @media only screen and (min-width: #{map-get($breakpoints, sm)}) {
        font-size: 4rem; } }
