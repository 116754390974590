.block-contact {
    padding: 100px 100px;
    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 70px 50px; }
    @media (max-width: #{map-get($breakpoints, lg)}) {
        padding: 20px 25px; }

    .contact-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: auto;


        .contact-wrapper-inner {
            display: flex;
            max-width: $boxed;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            @media (max-width: #{map-get($breakpoints, xxl)}) {
                flex-direction: column; }


            .contact-left-container {
                width: 30%;
                display: flex;
                flex-direction: column;
                @media (max-width: #{map-get($breakpoints, xxl)}) {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%; }
                @media (max-width: #{map-get($breakpoints, lg)}) {
                    flex-direction: column; }
                h3 {
                    font-size: 4rem;
                    margin-bottom: 30px;
                    @media (max-width: #{map-get($breakpoints, xxxl)}) {
                        font-size: 3rem; }
                    @media (max-width: #{map-get($breakpoints, xxl)}) {
                        font-size: 2rem; } }

                .contact-left-container-inner {
                    width: 100%;
                    box-shadow: 0px 0px 15px 7px rgba(0,0,0,0.16);
                    margin-bottom: 30px;
                    padding: 50px;
                    @media (max-width: #{map-get($breakpoints, xxl)}) {
                        margin: 25px; } } }

            .contact-right-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                height: 100%;
                @media (max-width: #{map-get($breakpoints, xxl)}) {
                    width: 100%; }
                iframe {
                    width: 100%;
                    height: 770px;
                    @media (max-width: #{map-get($breakpoints, lg)}) {
                        height: 500px; }
                    @media (max-width: #{map-get($breakpoints, md)}) {
                        height: 450px; } } } } }

    .contact-left-contact-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        img {
            width: 25px;
            height: 25px;
            margin-right: 9px; }
        a {
            color: #1D1D1B;
            text-decoration: none;
            &:hover {
                text-decoration: underline; } } } }
