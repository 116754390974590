.block-slider-simple {
    padding: 100px 0 100px 0;
    .slider-simple-wrapper {
        .slider-simple-image-container {
            height: 642px;
            max-width: $boxed;
            width: 80%;
            margin: auto;
            @media (max-width: #{map-get($breakpoints, lg)}) {
                height: 500px; }
            @media (max-width: #{map-get($breakpoints, md)}) {
                height: 400px; }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; } } }

    .disabled {
        display: block!important; }
    .owl-nav {
        bottom: 50%;
        margin-left: 5%;
        display: flex!important;
        justify-content: space-between;
        position: absolute;
        width: 90%;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            bottom: 0%;
            position: relative;
            justify-content: center;
            padding: 10px 0; }
        .owl-carousel-pfeil {
            img {
                width: 50px;
                height: 50px;
                border: 2px solid black;
                border-radius: 50%;
                padding: 10px 15px;
                margin: 5px;
                transition: $transition;
                &:hover {
                    background-color: #b3b3b3;
                    transition: $transition; } } } } }
