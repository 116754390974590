footer {
    width: $max;
    height: 500px;
    background-image: url('/wp-content/uploads/footer-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
    font-size: 1.6rem;
    overflow: hidden;
    font-family: SegoeUI;
    @media (max-width: 1920px) {
        background-size: contain; }
    p, a {
        color: #fff;
        font-size: 1.6rem;
        font-family: SegoeUI; }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: #fff; } }
    .footer-wrapper-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(280deg, rgba(255,255,255,0) 50%, rgba(0,0,0,1) 75%); }
    .footer-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        z-index: 10;
        position: relative;
        .footer-contact-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .footer-contact-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 15px;
                img {
                    width: 25px;
                    height: 25px;
                    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(134deg) brightness(103%) contrast(102%);
                    margin-right: 9px; } } }
        .footer-menu-container {
            #footer-menu-id {
                display: flex;
                list-style: none;
                text-decoration: none;
                li {
                    margin-right: 15px;
                    &:hover {
                        text-decoration: underline; } } } } }



    #footer-trecker-spuren {
        position: absolute;
        width: 330px;
        height: auto;
        left: -20px;
        bottom: -90px; } }
