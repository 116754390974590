.block-headline {
    padding: 100px 0 0px 0;
    @media (max-width: #{map-get($breakpoints, xl)}) {
        padding: 60px 0 10px 0; }
    @media (max-width: #{map-get($breakpoints, lg)}) {
        padding: 20px 0 10px 0; }
    &--align-left {
        display: flex;
        justify-content: flex-start; }

    &--align-center {
        display: flex;
        justify-content: center; } }
