.block-banner {
    background-color: $primary;
    text-align: center;
    position: relative;
    overflow: hidden;
    .banner-wrapper-typ1 {
        .banner-inner {
            padding: 100px 0;
            width: 35%;
            margin: auto;
            @media (max-width: #{map-get($breakpoints, lg)}) {
                width: 80%; } } }

    .banner-wrapper-typ2 {
        .banner-inner {
            padding: 100px 100px;
            max-width: $boxed;
            text-align: left;
            @media (max-width: #{map-get($breakpoints, md)}) {
                padding: 50px 50px; }
            @media (max-width: #{map-get($breakpoints, sm)}) {
                padding: 20px 20px; } } }

    .banner-wrapper-typ3 {
        .banner-inner {
            padding: 40px;
            width: 100%;
            text-align: center;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: none; } }
            h2 {
                margin: 0;
                border-bottom: 2px solid transparent;
                transition: $transition;
                display: inline-block; }
            @media (max-width: #{map-get($breakpoints, lg)}) {
                padding: 40px 90px; }
            @media (max-width: #{map-get($breakpoints, md)}) {
                padding: 40px 10px; } }
        &:hover {
            h2 {
                border-bottom: 2px solid #fff;
                transition: $transition; } } }


    .grid, .row {
        overflow: visible; }
    h2, p {
        color: #fff;
        padding: 0; }

    h2 {
        margin-bottom: 20px; }
    p {
        margin-bottom: 35px;
        font-family: 'RalewaySemiBold'; }

    .banner-deko {
        position: absolute;
        height: 100%; }

    .banner-deko-left {
        top: 0;
        bottom: 0;
        left: -50px;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            left: -135px;
            opacity: 0.3; } }
    .banner-deko-right {
        right: -50px;
        top: 0;
        bottom: 0;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            right: -135px;
            opacity: 0.3; } }


    .banner-deko-right-up {
        top: -35%;
        right: 10%;
        transform: rotate(-90deg);
        @media (max-width: #{map-get($breakpoints, xl)}) {
            top: -55%; }
        @media (max-width: #{map-get($breakpoints, lg)}) {
            opacity: 0.3; } }
    .banner-deko-right-down {
        bottom: -35%;
        right: 10%;
        transform: rotate(-90deg);
        @media (max-width: #{map-get($breakpoints, xl)}) {
            bottom: -55%; }
        @media (max-width: #{map-get($breakpoints, lg)}) {
            opacity: 0.3; } }

    ul {
        color: white;
        margin-left: 25px; }


    .animated-tractor {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        z-index: 5;
        animation: Tractor 30s linear infinite;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            animation: Tractor 10s linear infinite; }
        object {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(5deg) brightness(112%) contrast(101%);
            width: 50px;
            height: auto; } } }


@keyframes Tractor {
    0%, {
        transform: translateX(-60%); }
    50%, {
        transform: translateX(0%); }
    100%, {
        transform: translateX(60%); } }
