@font-face {
    font-family: 'RalewayRegular';
    font-style: normal;
    src: url('../../fonts/raleway/raleway-v27-latin-regular.woff2') format('woff2'),
    url('../../fonts/raleway/raleway-v27-latin-regular.woff') format('woff') {} }

@font-face {
    font-family: 'RalewaySemiBold';
    font-style: normal;
    src: url('../../fonts/raleway/raleway-v27-latin-700.woff2') format('woff2'),
    url('../../fonts/raleway/raleway-v27-latin-700.woff') format('woff') {} }

@font-face {
    font-family: 'RalewayBold';
    font-style: normal;
    src: url('../../fonts/raleway/raleway-v27-latin-900.woff2') format('woff2'),
    url('../../fonts/raleway/raleway-v27-latin-900.woff') format('woff') {} }

@font-face {
    font-family: 'SegoeUI';
    font-style: normal;
    src: url('../../fonts/SegoeUI/SegoeUI.woff2') format('woff2'),
    url('../../fonts/SegoeUI/SegoeUI.woff') format('woff') {} }
