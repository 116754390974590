header {
    width: 100%; }

.hero-wrapper {
    width: 100%;
    height: 90vh;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .hero-wrapper-overlay {
        position: absolute;
        opacity: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6699054621848739) 48%); }
    .hero-wrapper-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .hero-text-container {
            text-align: center; }
        h1, p {
            color: #fff;
            text-align: center; }
        .hero-arrow-container {
            margin-top: 25px;
            animation: headerArrow 1s ease 0s infinite; } } }


@keyframes headerArrow {
    0%,
    100% {
        transform: translateY(0); }

    50% {

        transform: translateY(-10px); } }
