.block-logo-wall {
    .logo-wall-wrapper {
        display: flex;
        justify-content: space-between;
        .logo-wall-logo-container {
            margin: auto 0;
            @media (max-width: #{map-get($breakpoints, xl)}) {
                width: 250px;
                height: 120px; }
            img {
                max-width: 100%;
                height: 100%;
                object-fit: contain;
                margin: auto; } }
        @media (max-width: #{map-get($breakpoints, xl)}) {
            flex-wrap: wrap;
            justify-content: space-evenly; }
        @media (max-width: #{map-get($breakpoints, lg)}) {
            padding: 5px 0 35px 0; } } }
