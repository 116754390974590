/*Variables: Timeline* */

$timeline_primary: #1D1D1B;
$timeline_seondary: $primary;
$timeline_tertiary: #F5F5F5;

.block-timeline {
    max-width: $max;
    padding: 0 100px;
    @media (max-width: #{map-get($breakpoints, lg)}) {
        padding: 0 50px; }
    @media (max-width: #{map-get($breakpoints, md)}) {
        padding: 0 10px; } }
.timeline {
    padding: 50px 0;
    position: relative;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: $timeline_tertiary;
    &::after {
        content: "";
        position: absolute;
        width: 6px;
        background-color: #fff;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -3px;
        box-sizing: border-box;
        margin-top: 100px;
        margin-bottom: 235px;
        @media (max-width: #{map-get($breakpoints, lg)}) {
            margin-left: -2.5px; } } }

.timeline-headline-container {
    padding: 80px 0;
    h2 {
        text-align: center; }
    @media (max-width: #{map-get($breakpoints, lg)}) {
        padding: 40px 0; } }




.container-timeline {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    box-sizing: border-box;

    &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        right: -16px;
        background-color: $primary;
        border: 1px solid $primary;
        top: 50px;
        border-radius: 50%;
        z-index: 1; } }

.timeline-left {
    left: 0; }

.timeline-right {
    left: 50%; }

.timeline-left {
    &::before {
        content: " ";
        height: 0;
        position: absolute;
        top: 55px;
        width: 0;
        z-index: 1;
        right: 30px;
        border: medium solid white;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent white; }
    .content-timeline {
        width: 70%;
        margin-left: auto;
        @media (max-width: #{map-get($breakpoints, xl)}) {
            width: 100%;
            margin-left: 0; } } }

.timeline-right {
    &::before {
        content: " ";
        height: 0;
        position: absolute;
        top: 55px;
        width: 0;
        z-index: 1;
        left: 30px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent; }
    &::after {
        left: -16px; }
    .content-timeline {
        width: 70%;
        margin-right: auto;
        @media (max-width: #{map-get($breakpoints, xl)}) {
            width: 100%;
            margin-right: 0; } } }

.content-timeline {
    padding: 10px 15px;
    background-color: white;
    position: relative;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 0;
    box-shadow: 0px 0px 15px 10px rgba(0,0,0,0.10); }

/*Fonts*/
.content-timeline {
    h3 {
        font-size: 2em;
        color: $timeline_seondary;
        margin: 25px 10px 10px 10px; }
    p {
        margin: 0px 2%; } }

@media (max-width: #{map-get($breakpoints, xl)}) {

    .timeline-right {
        left: 0%; }

    .timeline::after {
        left: 31px; }

    .container-timeline {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
        box-sizing: border-box;
        &::before {
            left: 60px;
            border: medium solid white;
            border-width: 10px 10px 10px 0;
            border-color: transparent white transparent transparent;
            box-sizing: border-box; } }

    .timeline-left {
        &::after {
            left: 13px; } }

    .timeline-right {
        &::after {
            left: 13px; } } }
